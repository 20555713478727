import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Logo from '../elements/Logo'
import { colors } from '../../tailwind'

import { Divider } from '../elements/Dividers'

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

const Text = styled.p`
  text-align: center;
  width: 100%;
  color: #fcd9b6;
  a {
    color: #fcd9b6;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Header = ({ offset }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          nodes {
            frontmatter {
              slug
            }
          }
        }
        markdownRemark(frontmatter: { slug: { eq: "notfound" } }) {
          html
        }
      }
    `
  )
  return (
    <Divider speed={0.1} offset={offset}>
      <HeaderContainer>
        <Logo color={colors.yellow} size="normal" />
        <Text
          dangerouslySetInnerHTML={{
            __html: data.markdownRemark.html,
          }}
        />
      </HeaderContainer>
    </Divider>
  )
}

export default Header
