import React from 'react'

import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { Parallax } from 'react-spring/renderprops-addons.cjs'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import CookieConsent from '../components/CookieConsent'
import Footer from '../components/Footer'
import NotFound from '../components/NotFound'
import Layout from '../components/Layout'
import Logo from '../elements/Logo'
import { colors } from '../../tailwind'

const Container = styled.div`
  ${tw`font-sans h-screen`};
`

const HeadLinks = styled.nav`
  ${tw`font-sans fixed w-full z-10`};
  padding: 10px 5%;
  background-color: ${colors['teal-darker']};
  top: 0;
  left: 0;
`

const Anchor = styled(OutboundLink)`
  ${tw`sm:text-base lg:text-sm xl:text-base`}
  transition: color 0.2s ease-out;
  color: ${props => colors[props.color]};
  display: block;
  padding: ${props => (props.isDesk ? '0' : '4px 0')};
  &:hover {
    color: #fff;
  }
`

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props)

    this.parallax = React.createRef()
  }

  render() {
    return (
      <>
        <HeadLinks>
          <Anchor href="/" color="teal">
            <Logo color="#fff" size="tiny" />
          </Anchor>
        </HeadLinks>

        <Layout>
          <Container>
            <Parallax pages={2} ref={this.parallax}>
              <NotFound offset={0} />
              <Footer offset={1} />
            </Parallax>
          </Container>
        </Layout>
      </>
    )
  }
}

export default NotFoundPage
